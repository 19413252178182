<template>
  <div id="page-ladok-search">
    <MemberEditorCompactModal
      ref="member-editor-compact-modal-tab"
      class="mt-8"
      :member_id="member_id"

    />


    <b-modal ref="info-popup" hide-footer>
      
      <div class="d-flex flex-column">
        <div class="w-100 h-100 align-items-center text-center flex-wrap justify-content-center">
          <i class="fa fa-exclamation-triangle" style="font-size: 42px; color: #eca350;"></i>
        </div>

      <hr />

        <div>
          
          <div class="w-100 align-items-center text-center justify-content-center">

            <h4 class="header-name mt-8" style="text-align: center; flex: 1;">{{ $t('LADOK.XLSX_JOB_HEADER') }}</h4>
            <br />
            <p class="mt-8">{{ $t('LADOK.XLSX_JOB_INFO') }}</p>

            <p class="mt-8"><b-button variant="success" @click.prevent="$refs['info-popup'].hide()">{{$t('COMMON.CONTINUE')}}</b-button></p>

          </div>
        </div>
      </div>
    </b-modal>
    
    <div class="d-sm-flex justify-content-start align-bottom ml-4 mt-4">
      <form @submit="on_submit">
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('LADOK.SEARCH_INPUT')" label-for="search_text-name">
                <b-form-input autocomplete="off"
                style="width: 460px;"
                id="shop_item-name"
                v-model="search_text"
                :placeholder="$t('LADOK.SEARCH_PLACEHOLDER')"
              />
            </b-form-group>
          </b-col>
        </b-row>
       
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('LADOK.PERIOD')" label-for="period-id">
              <b-form-select
                id="period-id"
                v-model="period_id"
                :options="period_options"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :label="$t('LADOK.STATUS')" label-for="status">
              <b-form-select
                id="status"
                v-model="status"
                :options="status_options"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </form>
      

      <div class="d-sm-flex justify-content-end align-items-start align-start w-100 mb-4">
      
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
          @click.prevent="export_csv"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('COMMON.EXPORT_CSV')}}</a
        >

      </div>

    </div>


    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">
          <b-pagination
            class="mb-0"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="ladok-table"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
    
    <b-table
      style="width: 100%; table-layout: fixed;"
      id="ladok-table"
      class="table-striped"
      :items="ajax_pagination"
      :fields="fields"
      :filter="filters"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
      ref="ref_ladok_table"

    >
      <template #cell(actions)="row">
        <div v-if="row.item.member" class="justify-content-end d-flex">
          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="select_member_clicked(row.item.member.member_id)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-user color-primary"></i>
            </span>
          </a>
        </div>
        <div v-else class="justify-content-start d-flex">
          {{ $t('MEMBER.NO_MEMBER') }}
        </div>
      </template>
    </b-table>

 </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { is_mobile } from '@/core/services/utils';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import dayjs from 'dayjs';


export default {
  name: 'LADOKSearchTable',
  components: {

  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods']),
    period_options() {
      return this.periods.map(( item ) => ( { text: item.name, value: item.id }));
    },
    status_options() {
      return [
        { text: this.$t('LADOK.STATUSES.MEMBER'), value: 'MEMBER' },
        { text: this.$t('LADOK.STATUSES.NOT_MEMBER'), value: 'NOT_MEMBER' },
        { text: this.$t('LADOK.STATUSES.ALL'), value: null },
      ]
    }
  },
  mounted() {
    
    this.period_id = this.currentPeriodId;
  },
  data() {
    return {
      status: null,
      period: { name: '' },
      period_id: null,

      member_id: null,

      filters: {},

      per_page: 100,
      current_page: 1,
      search_text: null,

      total_rows: 0,

      fields: [

        {
          visible: is_mobile() ? false : true,
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          sortable: true,
          formatter: (_, __, item) => {
            return dayjs(item.created_at).format('YYYY-MM-DD');
          },
          sortByFormatted: true,
          tdClass: 'w-165px td-overflow',
          thClass: 'td-overflow'
        },

        {
          visible: true,
          key: 'student_id',
          label: this.$t('MEMBER.STUDENT_ID'),
          sortable: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          visible: true,
          key: 'fnamn',
          label: this.$t('MEMBER.NAME'),
          sortable: false,
          formatter: (_, __, item) => {
            return item.fnamn + ' ' + item.enamn;
          },
          sortByFormatted: true,
          tdClass: 'w-165px td-overflow',
          thClass: 'td-overflow'
        },

        {
          visible: true,
          key: 'kurskod',
          label: this.$t('LADOK.KURSKOD'),
          sortable: false,
          sortByFormatted: true,
          tdClass: 'w-165px td-overflow',
          thClass: 'td-overflow'
        },

        {
          visible: true,
          key: 'programkod',
          label: this.$t('LADOK.PROGRAMKOD'),
          sortable: false,
          sortByFormatted: true,
          tdClass: 'w-165px td-overflow',
          thClass: 'td-overflow'
        },

        {
          label: this.$t('LADOK.MEMBER'),
          visible: true,
          key: 'actions',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        }
      ]
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {

      }
    },
    currentPeriodId(newValue, oldValue) {
      this.period = this.periods.find(item => item.id === newValue);
      this.period_id = newValue;

      this.$refs['ref_ladok_table'].refresh();
    },

    period_id() {
      this.$refs['ref_ladok_table'].refresh();
    },
    status() {
      this.$refs['ref_ladok_table'].refresh();
    },
  },
  methods: {
    async export_csv() {

      try {
        const res = await axios.post(`/ladok/csv`, { 
          options: {
            text: this.search_text,
            period_id: this.period_id,
            status: this.status
          } 
        });

        if (res.status === 201) {
          this.$refs['info-popup'].show();
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('LADOK.UNABLE_START'));
        }
      }
      catch (err) {
        console.error('export_csv error', err);
      }

    },
    on_submit(e) {
      e.preventDefault();

      this.$refs['ref_ladok_table'].refresh();
    },
    select_member_clicked(member_id) {
      this.member_id = member_id;
      this.$refs['member-editor-compact-modal-tab'].show();
    },

    get_pagination(callback) {
      axios
        .post(`/ladok/pagination`, {
          options: {
            text: this.search_text,
            period_id: this.period_id,
            status: this.status
          },
          page: this.current_page,
          limit: this.per_page
        })
        .then(res => {
          if (res.status !== 200) {
            console.error('pagination: invalid status', res.status);
            return;
          }

          var arr = res.data;

          if (callback) {
            callback(arr || []);
          }
        })
        .catch(err => {
          console.error('pagination error', err);
        });
    },

    get_count() {
      axios
        .post(`/ladok/pagination/count`, {
          options: {
            text: this.search_text,
            period_id: this.period_id,
            status: this.status
          }
        })
        .then(res => {
          if (res.status !== 200) {
            console.error('pagination count: invalid status', res.status);
            return;
          }

          this.total_rows = res.data.count;

        })
        .catch(err => {
          console.error('pagination count error', err);
        });
    },

    ajax_pagination: function(ctx, callback) {
      const vm = this;
      let params = {
        pageNo: ctx.current_page,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_count();
      this.get_pagination(callback);

      return null;
    },

  }
};
</script>


<style lang="scss" scoped>



</style>